/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.custom-dialog .mat-dialog-container {
    border-radius: 8px;
    overflow: hidden;
}

.custom-dialog .cardWithShadow {
    border-radius: 8px;
}

.custom-dialog-content {
    overflow-y: auto;
    max-height: 100%; /* Adjust this value based on your header/footer height */
    box-sizing: border-box;
}

.no-data {
    text-align: center;
    margin-top: 100px;
    p {
        color: gray;
    }
}